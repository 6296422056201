import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import AuthContext from "../context/Auth";
import "./DetailsMain.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Skeletonprice } from "../../Skeletons/Skeletons.jsx";
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from "react-router-dom";
// import UserReviews from "./UserReviews";
import AuthContext from "../../Providers/AuthProvider";
import UsersReviews from "../../Components/UsersReviews/index.jsx";
import { addProductReviewAPI, addToCartAPI, editProductReviewAPI, getProductDataByIdAndSimilarProductsAPI, getProductReviewAPI } from "../../api/index.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { getValue } from "@testing-library/user-event/dist/utils/index.js";
import { useSelector } from "react-redux";
const reviewSchema=yup.object({
    "product":yup.string().required('product Id is Required'),
    "rating": yup.number().required('Please Rate The Product Before Saving The Review'),
    "comment": yup.string()
})

const ProductDetails = () => {
  const {formState,register,setValue,getValues,handleSubmit}=useForm({resolver:yupResolver(reviewSchema),defaultValues:{
    product:null,
    rating:0,
    comment:null
  }})
  const { id } = useParams();
  const [method,setMethod] = React.useState("POST")
  // const {authTokens,user} = React.useContext(AuthContext);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [PinCode, setPinCode] = useState("400020");
  const [EditState, setEditState] = useState(false);
  const [Data, setdata] = React.useState();
  const [quantity, setquantity] = React.useState(0);
  const [AvgRating, setAgRating] = React.useState(3);
  const [AddReviewState,setAddReviewState] = React.useState(false)
  const [ReviewText,setReviewText] = React.useState()
  const [Reviews,setReviews] = React.useState([])
  const [averagereview,setaveragereview] = React.useState(0)
  const navigate = useNavigate();
  const {authTokens,User }=useSelector((state)=>state)
  const changequantaty = async (q) => {
    if (authTokens && Data) {
   
      const data=await addToCartAPI({product:id,quantity:q})
    
      console.log(data);
    }
  };
  const handleEditState = () => {
    setEditState(!EditState);
  };

  const handlePincodeChange = (e) => {
    setPinCode(e.target.value);
  };
  
  const getReviews=async(productId)=>{
 const {data}=await getProductReviewAPI({productId});
console.log(data)
    setReviews([...data.productReviews])
    if(data.userReview)
    {
      let {comment,rating}=data.userReview
      setValue('comment',comment);
      setMethod('PATCH')
      setRating(rating)
    }
    console.log("reviews",data)
    let sum=0
    if (data.productReviews.length>0){
      data.productReviews.map((review)=>{
      sum+=review.rating
      
    })
    setaveragereview((sum/data.productReviews.length).toFixed(1))
  }
  }
  
  

  const fetchdata = async () => {
    try {
        

          try {
            const res=await getProductDataByIdAndSimilarProductsAPI(`/api/product/${id}`)
            console.log("res.product",res.product)
            setdata(res.data.product);
            setquantity(res.data.product["quantity"]);

          } catch (error) {
            
          }
        
    } catch (error) {
        
    }
  };


  const handleAddReview = async(e) => {
    if(method==='PATCH' && rating>0 && User)
    {
const data=await editProductReviewAPI({...e,productId:e.product,userId:User._id})
getReviews(e.product)

    }
    else if(User && rating>0 && method==='POST')
    {
      // const userid=jwtDecode(authTokens["accesstoken"])["_id"]||''
     const data=await addProductReviewAPI({...e,productId:e.product,userId:User._id})
     
     getReviews(e.product)
  
    
  }
    else if(!User){
      navigate("/auth/signupuser")
    }
    if (AddReviewState){
      // write add review code
      
    }
    setAddReviewState(!AddReviewState)
  }
  // const getuserreviewstatus=async()=>{
  //   if(authTokens){
  //     const userid=jwtDecode(authTokens["access"])["user_id"]
  //     const response=await fetch(`https://api-krudra9125-gmailcom.vercel.app/api/review/${id}`,{
  //       method:"GET",
  //       headers:{
  //         "Content-Type":"application/json",
  //         Authorization: `Bearer ${authTokens["access"]}`,
  //       },
  //     })
  //     const data=await response.json()
  //     if(data["message"]==='no reviews')
  //     {
  //       console.log("no reviews")
  //       setMethod("POST")
  //       setRating(0)
  //       setReviewText("")
        
  //     }
  //     else{
  //       setMethod("PATCH")
  //       setRating(data["rating"])
  //       setReviewText(data["comment"])
  //     }
  //   }}
  
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);

    fetchdata();
    getReviews(id)
    setValue('product',id)
    // getuserreviewstatus()
  }, [id]);
  

useEffect(()=>{
setValue('rating',rating)
},[rating])
  return (
    <div>
      <div className="w-[100%] h-[100%] ">
        <div className="w-[100%] h-[100%] max-w-screen-2xl mx-auto flex flex-col justify-start items-center min-h-fit  ">
          <div className="DetailsOuter flex AroundFlex">
            <div className="ImageWrapperDiv">
              <div
                className="ProductNameHeader mobileProduct"
                style={{ padding: "10px 10px" }}
              >
                <h3>{Data ? Data["name"] : ""}</h3>
              </div>
              <div
                className=" w-[100%] md:w-[50%] flex justify-center items-center flex-col AddGap"
                style={{ padding: "5px 10px" }}
              >
                <div
                  className="w-[100%] ProductPriceDiv mobileProduct"
                  style={{ marginLeft: "5px" }}
                >
                  <span className="ProductPrice">
                    {Data?.price ? Data["price"] : <Skeletonprice />}
                  </span>
                </div>
              </div>
              {Data ? (
                <div className="DetailsImageDiv">
                  <div className="DetailsImageOuter">
                    <div className="ImageWrap">
                      {console.log("this is data", Data)}
                      <img
                        width={150}
                        className="object-fill w-full h-full aspect-video"
                        src={`${Data["img_path"]}`}
                        alt={"category"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Skeleton className="h-[400px] min-w-[300px] w-[100%] rounded-[30px]" />
              )}{" "}
              <div className="w-[100%] AddToCartWrapper ">
                {quantity > 0 ? (
                  <div className="w-[8rem] h-[100%] flex justify-center items-center">
                    <div className="w-[33.33%] h-[100%] flex justify-center">
                      <div
                        className=" bg-yellow-300  text-center font-[900] QuantityIcon text-green-800  rounded-full cursor-pointer "
                        onClick={() => {
                          if (quantity > 0) {
                            setquantity(quantity - 1);
                            changequantaty(-1);
                          } else {
                            setquantity(0);
                          }
                        }}
                      >
                        -
                      </div>
                    </div>
                    <div className="w-[33.33%] h-[100%] flex justify-center items-center text-center">
                      {" "}
                      {quantity}
                    </div>
                    <div className="w-[33.33%] h-[100%] flex justify-center ">
                      <div
                        className="bg-yellow-300  text-center font-[900] text-green-800  rounded-full cursor-pointer QuantityIcon "
                        onClick={() => {
                          setquantity(quantity + 1);
                          changequantaty(1);
                        }}
                      >
                        +
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className="w-[100%] h-[90%]  shadow-md rounded-md bg-yellow-300 text-green-800 text-xs font-bold  AddToCartButton"
                    onClick={() => {
                      if (authTokens) {
                        setquantity(quantity + 1);
                        changequantaty(1);
                      } else {
                        console.log("authTokens",authTokens)
                        navigate("/auth/signupuser");
                      }
                    }}
                  >
                    {" "}
                    Add to cart
                  </button>
                )}
              </div>
            </div>

            <div className="ProductDetailsDiv">
              <div className="ProductNameHeader laptopProduct">
                <h3>
                  {Data ? Data["name"] : <Skeleton width={60} height={20} />}
                </h3>
              </div>
              <div className=" w-[100%] md:w-[50%] flex justify-center items-center flex-col AddGap laptopProduct">
                <div
                  className="w-[100%] ProductPriceDiv laptopProduct"
                  style={{ marginLeft: "5px" }}
                >
                  <span className="ProductPrice">
                    {Data ? Data["price"] : <Skeleton width={60} height={20} />}
                  </span>
                </div>
              </div>

              {/* <div className="DeliverDiv">
                <h1 className="DeliverHeader">Deliver to</h1>
                <div
                  className="DeliverPinCode"
                  style={EditState ? { display: "flex" } : { display: "none" }}
                >
                  <input
                    type="text"
                    onChange={handlePincodeChange}
                    value={PinCode}
                  />
                  <div className="IconOk" onClick={handleEditState}>
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20px"
                      height="20px"
                      viewBox="0 0 335.765 335.765"
                      space="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  className="InputIcon"
                  style={EditState ? { display: "none" } : { display: "flex" }}
                >
                  {PinCode}
                  <div className="EditIcon" onClick={handleEditState}>
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z"
                          fill="#080341"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                </div>
              </div> */}

              <div className="ProductInfo">
                <h2>Product Information</h2>
                <div className="w-[100%] flex flex-col md:flex-row justify-start">
                  <div className="w-[100%]">
                    <div className="ProductTable">
                      <div className="ProductTableRow">
                        <div className="ProductTableTd1">BRAND</div>
                        <div className="ProductTableTd2">
                          {Data ? (
                            Data["brand"]
                          ) : (
                            <Skeleton width={60} height={20} />
                          )}
                        </div>
                      </div>
                      <div className="ProductTableRow">
                        <div className="ProductTableTd1">WEIGHT</div>
                        <div className="ProductTableTd2">
                          {Data ? (
                            Data["weight"]
                          ) : (
                            <Skeleton width={60} height={20} />
                          )}
                        </div>
                      </div>
                      <div className="ProductTableRow">
                        <div className="ProductTableTd1">FLAVOUR</div>
                        <div className="ProductTableTd2">
                          {Data ? (
                            Data["flavour"]
                          ) : (
                            <Skeleton width={60} height={20} />
                          )}
                        </div>
                      </div>
                      <div className="ProductTableRow">
                        <div className="ProductTableTd1">CATEGORY</div>
                        <div className="ProductTableTd2">
                          {Data ? (
                            Data["category"]
                          ) : (
                            <Skeleton width={60} height={20} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="DescHeader">
                <h3>Description</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Excepturi saepe illum molestias iusto fugiat quas consectetur
                  nihil quia laborum illo officia omnis sit error voluptatem
                  autem eos facilis mollitia vero, necessitatibus amet ipsa hic
                  maxime ab! Magnam, ipsa amet? Porro cumque illo corporis
                  itaque dicta pariatur dolore, earum blanditiis nesciunt, cum
                  dignissimos quae neque labore consequatur explicabo impedit
                  laudantium recusandae numquam accusantium nihil vitae, facere
                </p>
              </div>
            </div>
          </div>
          <div className="RatingsDiv">
            <div className="RatingsWrap">
              <div className="RatingsStarDivWrap">
                <div className="RatigsStars">
                <div>

                  <h1 className="H1Ratings CustomerHeader">Ratings & Reviews</h1>
                  <h1 className="AverageStars">{averagereview}</h1>
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={
                            index <= averagereview
                              ? "StarButton on"
                              : "StarButton off"
                          }
                        >
                          <span className="star">&#9733;</span>
                        </button>
                      );
                    })}
                  </div>
                  <form onSubmit={handleSubmit(handleAddReview)} className="writeReview">
                    <h1>Write a Review</h1>
                    <p>Help others make an informed decision!</p>
                    <div className="UserTakingReview">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover ||rating)
                                ? "UserStarReview on"
                                : "UserStarReview off"
                            }
                            onClick={() => {setRating(index)}}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                          >
                            <span className="starReview">&#9733;</span>
                          </button>
                        );
                      })}
                    </div>
                    <div className="InputReview" >
                      <textarea name="" id="" cols="16" rows="2" {...register('comment')} onChange={(e)=>setReviewText(e.target.value)}></textarea>
                    </div>
                    <button type="submit" className="WriteReviewButton" >
                     {method==="PATCH"?"Edit The Review":"Post The Review"}
                    </button>
                  </form>
                </div>
                <div className="UserReviewsDiv">
                  <h1
                    style={{ fontSize: "xx-large", fontWeight: "700" }}
                    className="CustomerHeader"
                  >
                    Reviews from customers
                  </h1>
                  
                {Reviews.length>0 ? Reviews.map((review,index)=>{
                  return <UsersReviews key={index} review={review}/>
                }):<h1>No Reviews Available</h1>}
                  {/* <UserReviews />
                  <UserReviews />
                  <UserReviews /> */}
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
