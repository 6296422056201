import React from 'react'

const CustomInput = ({className,type,placeholder,register,fieldName,seepass,error}) => {
  console.log("error message", error);
  return (
 <div className="w-[100%]  flex flex-col items-center my-2 ">
                          <div className={`w-[100%] h-[3rem] min-h-[3rem] flex  border  ${error?"border-red-600":"border-black"}  rounded-xl `}>
    <input
                              className={`w-[100%] h-[100%] rounded-xl outline-none px-2 ${className}`}
                              type={`${type==="password"?seepass ? "text" : "password":type}`}
                              placeholder={placeholder}
                              {...register(`${fieldName}`)}
                              
                              // required={true}
                              />
                              </div>
                              {error && <span className=' text-xs w-full text-start pl-2 text-red-600  '>{error}</span>}
                              </div>

  )
}

export default CustomInput