import React, { useEffect } from "react";
import CartCard from "./CartCard";
import "./Cart.css";
import { useNavigate } from "react-router-dom";
import { SkeletonCart } from "../../Skeletons/Skeletons";
import { useDispatch, useSelector } from "react-redux";
const Cart = () => {
  const cartdataa=useSelector((state)=>state.Cart)
  const [cartdata, setCartdata] = React.useState(Object.values(cartdataa.data));
  const [FetchState, setFetchState] = React.useState(false);
  const [total, setTotal] = React.useState(0);
 

  useEffect(()=>{
setCartdata(()=>(Object.values(cartdataa.data)))
  },[cartdataa])
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    let sum = 0;
    cartdata?.map((item) => {
      sum = sum + item["product"]["price"] * item["quantity"];
      return null;
    });
    console.log(sum);
    setTotal(sum);
  }, [cartdata]);

  const changeSubtotal = (value)=>{
    const newtotal = total + value
    setTotal(newtotal)
  }
// console.log(cartdata,"cartdata");
  return (
    <div className="w-[100%] min-h-[100vh] mt-[0rem]">
      <div className="responsive w-[100%] h-[100%] max-w-screen-2xl mx-auto flex flex-row justify-start  min-h-fit  ">
        <div className="CartOuter">
          <div className="CartHeader">
            <h1>Shopping Cart</h1>
          </div>
          <hr />
          <div className="CartWrapper">
            {cartdata ? (
              cartdata && cartdata.length !== 0 ? (
                cartdata.map((item) => {
                  return (
                    <CartCard
                      productId= {item["product"]['_id']}
                      name={item["product"]["name"]}
                      price={item["product"]["price"]}
                      weight={item["product"]["weight"]}
                      category={item["product"]["category"]}
                      imagsrc={item["product"]["img_path"]?? item["product"]["imagsrc"] ??item["product"]["imgSrc"]}
                      Quantity={item["quantity"]}
                      changeSubtotal = {changeSubtotal}
                    
                    />
                  );
                })
              ) : (
                <h1 style={{fontSize:'xx-large'}}>No Items Added</h1>
              )
            ) : (
              [1, 1, 1, 1, 1].map((item) => {
                return <SkeletonCart />;
              })
            )}
          </div>
        </div>
        <div className="SubtotalOuter">
          <div className="SubtotalCard">
            <div className="SubtotalHeader">
              <p>
                Subtotal : <span>{total}</span>
              </p>
            </div>
            <button>Proceed To Buy</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
